import React from 'react';
import styled from 'styled-components';
import { textAreaStyles } from './inputTextArea.styles';

export type TextAreaProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  disabled?: boolean;
  width?: number;
  height?: number;
  margin?: string;
  value?: string;
  error?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const TextArea = styled.textarea<any>`
  ${(props) => textAreaStyles(props)}
`;

export default TextArea;
