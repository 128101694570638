import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// Commons
import { Text, Button } from '../../commons';
// Material
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// Assets/Images
import logoHorizontal from '../../assets/images/logoHorizontal.png';
// Styles
import { useStyles } from './navBar.styles';
//Utils
import { removeItemFromLocalStorage } from '../../utils/storage';

interface NavProps extends RouteComponentProps {
  title?: string;
  icon?: string;
  handleClick?: () => void;
  handleClose?: () => void;
  handleLogout?: () => void;
  onClickMenu?: (path: string) => void;
  children?: React.ReactNode;
}

const NavList = [
  {
    text: 'Campagnes',
    path: 'campaigns',
  },
  // {
  //   text: 'A propos de France Rein',
  //   path: 'about-us',
  // },
  // {
  //   text: 'Contactez Nous',
  //   path: 'contact-us',
  // },
];

const NavBar: React.FC<NavProps> = (props) => {
  const handleLogout = () => {
    removeItemFromLocalStorage('token');
    removeItemFromLocalStorage('user');
    props.history.push('/login');
  };

  const onClickMenu = (path: string) => {
    props.history.push(`/${path}`);
  };

  const currentPath = window.location.pathname;
  const classes = useStyles();
  const { title } = props;
  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.appBar} elevation={0}>
        <div className={classes.logoContainer}>
          <img
            className={classes.logoSymbole}
            src={logoHorizontal}
            alt='logo'
          />
        </div>
        <div className={classes.actions}>
          <List disablePadding>
            {NavList.map((item, index) => {
              const selected = item.path
                ? currentPath.includes(item.path)
                : false;

              return (
                <ListItem
                  key={index}
                  selected={selected}
                  button
                  onClick={() => onClickMenu(item.path)}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={item.text}
                  />
                </ListItem>
              );
            })}
            <Button height={35} width={120} onClick={handleLogout}>
              Deconnexion
            </Button>
          </List>
        </div>
      </AppBar>
      <main className={classes.content}>
        {/* <div className={classes.toolbar} /> */}
        {props.children}
      </main>
    </div>
  );
};

export default withRouter(NavBar);
