import { css } from 'styled-components';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const screeningSheetProfessionalStyles = () => {
  return css`
    display: flex;
    flex: 1;
    /* align-items: center; */
    justify-content: center;
    margin-top: 80px;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
    .search-cont {
      display: flex;
      flex-direction: column;
      align-items: center;
      /* align-self:flex-start; */
      margin: 20px;
      width: 400px;
      .input-search {
        /* flex: 1; */
        position: relative;
        .input {
          box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
          border: none;
          border-radius: 60px;
        }
        .button-search {
          position: absolute;
          top: 12px;
          right: 4px;
          margin: 0;
          height: 42px;
        }
      }

      .card-user-cont {
        display: flex;
        align-items: center;
        position: relative;
      }
      .card-user {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 300px;
        width: 300px;
        margin-top: 40px;
        margin-bottom: 40px;
        padding: 10px;
        background-color: #fff;
        box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.1);
        border-radius: 20px;
        .avater {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 130px;
          height: 130px;
          box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.1);
          background: #fff;
          border-radius: 50%;
          margin-bottom: 30px;
        }
      }
    }

    .divider {
      border-left: 1px solid #efefef;
      border-right: 1px solid #efefef;
      height: 90%;
      right: 249px;
      top: 10px;
      @media only screen and (max-width: 768px) {
        border-top: 1px solid #efefef;
        border-bottom: 1px solid #efefef;
        width: 90%;
      }
    }

    .fiche-cont {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      margin: 20px;
    }

    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 700px;
      margin-top: 10px;
      margin-bottom: 20px;
      padding: 20px;
      background-color: #fff;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.1);
      border-radius: 20px;
      @media only screen and (max-width: 768px) {
        width: 400px;
      }
    }

    .form-control-checkbox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: initial;
        justify-content: initial;
      }
    }
    .form-group {
      flex-direction: row;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    .form-control-label {
      width: 730px;
      @media only screen and (max-width: 768px) {
        width: 430px;
      }
    }

    .form-control-radio {
      display: flex;
      flex-direction: row;
      gap: 30px;
    }
    .fiche-cont-footer {
      width: 730px;
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      gap: 15px;
      @media only screen and (max-width: 768px) {
        width: 430px;
      }
    }

    .empty_data-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 80px;
    }

    .img-container {
      width: 180px;
      height: 180px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        filter: hue-rotate(312deg);
        opacity: 0.5;
      }
    }
    .label-input {
      margin-top: 10px;
      background: white;
      padding: 0 6px;
      left: -6px;
    }
  `;
};
