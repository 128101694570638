import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { css } from 'styled-components';

const drawerWidth = 250;
export const useStyles =  makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#ffffff',
      width: '100%',
      height: '60px',
      padding: '0px 15px',
    },
    logoContainer: {
      height: '30px',
      marginRight:'15px',
    },

    logoSymbole: {
      height: '100%',
      objectFit: 'contain',
    },
    actions: {
      display: 'flex',
      height: '100%',
    },
    listItemText: {
      fontSize: '16px',
      color: '#373737',
    },
    // toolbar: {
    //   display: 'flex',
    //   alignItems: 'center',
    //   justifyContent: 'center',
    //   // padding: theme.spacing(0, 1),
    //   // necessary for content to be below app bar
    //   ...theme.mixins.toolbar,
    // },
    content: {
      // overflow: 'hidden',
      display:'flex',
      // height: '100vh',
      width:'100vw',
      // padding: theme.spacing(2),
    },
  })
);
