import { css } from 'styled-components';

export const loginStyles = (props: any) => {
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .container-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .logo {
        margin-bottom: 30px;
      }
      .form {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;

        .inputs {
          display: flex;
          flex-direction: column;
          margin: 25px;
          /* justify-content: center; */
          /* align-items: center; */
          .forgot-password-txt {
            align-self: flex-end;
            margin-top: 5px;
            cursor: pointer;
          }
        }
      }
    }
  `;
};
