import {
    CAMPAIGNS_GET_LIST_DEPARTMENTS_SUCCESS,
    CAMPAIGNS_GET_LIST_DEPARTMENTS_FAILED,
    CAMPAIGNS_GET_LIST_CAMPAIGNS_SUCCESS,
    CAMPAIGNS_GET_LIST_CAMPAIGNS_FAILED,
    CAMPAIGNS_PROPS_CHANGED
  } from '../actions/types';
  import {
    CampaignsState,
    CampaignsActionTypes,
  } from '../modules/Campaigns/campaigns.types';
  
  const INITIAL_STATE: CampaignsState = {
    departments: [],
    department:'',
    campaigns:[],
    departmentDetail:null,
    messageError: '',
    loading: true,
  };
  
  export default (
    state = INITIAL_STATE,
    action: CampaignsActionTypes
  ): CampaignsState => {
    switch (action.type) {
      case CAMPAIGNS_PROPS_CHANGED:
        return { ...state, [action.prop]: action.value };

      case CAMPAIGNS_GET_LIST_DEPARTMENTS_SUCCESS:
        return {
          ...state,
          departments: action.payload,
          loading: false,
        };
  
      case CAMPAIGNS_GET_LIST_DEPARTMENTS_FAILED:
        return { ...state, messageError: action.payload, loading: false };

        case CAMPAIGNS_GET_LIST_CAMPAIGNS_SUCCESS:
          return {
            ...state,
            campaigns: action.campaigns,
            departmentDetail:action.departmentDetail,
            loading: false,
          };
    
        case CAMPAIGNS_GET_LIST_CAMPAIGNS_FAILED:
          return { ...state, messageError: action.payload, loading: false };
  
    //   case CAMPAIGNS_INITIAL_STATE:
    //     return { ...INITIAL_STATE };
  
      default:
        return state;
    }
  };
  