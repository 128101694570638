export const setItemInLocalStorage = (key: any, data: any) =>
  localStorage.setItem(key, JSON.stringify(data));

export const removeItemFromLocalStorage = (key: any) =>
  localStorage.removeItem(key);

export const getItemFromLocalStorage = (key: any) => {
  const data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  }
  return null;
};
