import axios from 'axios';
import { URL } from './configApi';
import {
  CREATE_SCREENING_SHEET_PROPS_CHANGED,
  CREATE_SCREENING_SHEET_SUCCESS,
  CREATE_SCREENING_SHEET_FAILED,
  CREATE_SCREENING_SHEET_INITIAL_STATE,
  CREATE_SCREENING_SHEET_PROPS_VALIDATION,
  AppThunk,
} from './types';
import {
  CreateScreeningSheetState,
  CreateScreeningSheetInitialStateAction,
  CreateScreeningSheetPropsChangedAction,
} from '../modules/CreateScreeningSheet/createScreeningSheet.types';

//Utils
import {
  validateFieldsCreateScreeningSheet,
  createScreeningSheetObject,
} from '../modules/CreateScreeningSheet/createScreeningSheet.utils';

export const createScreeningSheetPropsChanged = (
  prop: string,
  value: any
): CreateScreeningSheetPropsChangedAction => {
  return { type: CREATE_SCREENING_SHEET_PROPS_CHANGED, prop, value };
};

export const createScreeningSheetInitialState = (): CreateScreeningSheetInitialStateAction => {
  return { type: CREATE_SCREENING_SHEET_INITIAL_STATE };
};

export const createScreeningSheet = (
  props: CreateScreeningSheetState,
  campaignId: string,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newProps, valid } = validateFieldsCreateScreeningSheet(props);
  dispatch({
    type: CREATE_SCREENING_SHEET_PROPS_VALIDATION,
    payload: newProps, 
  });
  const ScreeningSheet= createScreeningSheetObject(
    newProps,
    campaignId
  );
  
  if (valid && ScreeningSheet) {
    dispatch({
      type: CREATE_SCREENING_SHEET_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });
    axios
      .post(`${URL}/screening_sheet`, ScreeningSheet)
      .then(({ data }: { data: any }) => {
        
        if (data.status === 'success') {
            dispatch({
              type: CREATE_SCREENING_SHEET_SUCCESS,
              payload:data.response.data,
            });
            callback();
           
       
        }
      })
      .catch((error) => {  
        console.log('error',error.response);
         
          dispatch({
            type: CREATE_SCREENING_SHEET_FAILED,
            payload: 'error',
          });
        
      });
  }
};

// setTimeout(() => {
//   dispatch({
//     type: SCREENING_SHEET_VOLUNTEER_PROPS_CHANGED,
//     prop: 'loading',
//     value: false,
//   });
//   dispatch({
//     type: SCREENING_SHEET_VOLUNTEER_PROPS_CHANGED,
//     prop: 'disabled',
//     value: false,
//   });
//   callback();
// }, 1000);
