import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
//Modules
import Login from './modules/Login';
import ResetPassword from './modules/ResetPassword';
import NotFound from './modules/NotFound';
import AboutUs from './modules/AboutUs';
import ContactUs from './modules/ContactUs';
//Routes
import CampaignsRouter from './routes/CampaignsRouter';
import './App.css';
// Reducers
import reducers from './reducers';
//Utils
import { PrivateRoute } from './utils/navigation';
import { removeItemFromLocalStorage } from './utils/storage';

const App: React.FC = () => {
  // removeItemFromLocalStorage('token');
  // removeItemFromLocalStorage('user');
  return (
    <Provider store={createStore(reducers, applyMiddleware(thunk))}>
      <Router>
        <Switch>
          <Redirect exact from='/' to='/login' />
          <Route
            path='/reset-password/:token'
            component={ResetPassword}
            exact
          />

          <Route path='/login' component={Login} exact />
          <PrivateRoute path='/campaigns' component={CampaignsRouter} />
          <PrivateRoute path='/about-us' component={AboutUs} />
          <PrivateRoute path='/contact-us' component={ContactUs} />
          <Route path='/*' component={NotFound} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
