import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Avatar,
  CircularProgress,
  FormLabel,
  Typography,
  Box,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
//Icons
import { Icon } from 'react-icons-kit';
import { ic_person_outline } from 'react-icons-kit/md/ic_person_outline';
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right';
import { ic_chevron_left } from 'react-icons-kit/md/ic_chevron_left';
import styled from 'styled-components';
import { screeningSheetProfessionalStyles } from './screeningSheetProfessional.styles';
//Types
import {
  ScreeningSheetProfessionalProps,
  FamilyHistoryProps,
  PersonalMedicalHistoryProps,
  ScreeningSheetProfessionalState,
} from './screeningSheetProfessional.types';
//Commons
import { Text, Input, Button, InputTextArea } from '../../commons';

//Components
import PdfDocument from '../../components/PdfDocument';
//Modules

//Assets/Images
import emptyState from '../../assets/images/emptyState.svg';
//Actions
import {
  screeningSheetProfessionalGetSheets,
  screeningSheetProfessionalGetSheetById,
  screeningSheetProfessionalPropsChanged,
  screeningSheetProfessionalInitialState,
  updateScreeningSheetProfessional,
  clickScreeningSheet,
  screeningSheetProfessionalFilterSheets,
} from '../../actions/screeningSheetProfessionalActions';

//Reducers
import { RootState } from '../../reducers';
import {
  BloodProps,
  GlucoseProps,
  ProteinProps,
} from '../CreateScreeningSheet/createScreeningSheet.types';

//Utils
import { getItemFromLocalStorage } from '../../utils/storage';
import { calculIMC } from './screeningSheetProfessional.utils';
const AGE = [
  {
    id: 1,
    text: '<= 18 ans',
    value: '<=18',
  },
  {
    id: 2,
    text: '18 - 40 ans',
    value: '18-40',
  },
  {
    id: 3,
    text: '41 - 60 ans',
    value: '41-60',
  },
  {
    id: 4,
    text: '> 60 ans',
    value: '>60',
  },
  {
    id: 5,
    text: '> 70 ans',
    value: '>70',
  },
  {
    id: 6,
    text: '> 80 ans',
    value: '>80',
  },
];

const GENDER = [
  {
    id: 1,
    text: 'Homme',
    value: 'male',
  },
  {
    id: 2,
    text: 'Femme',
    value: 'female',
  },
];

const ScreeningSheetProfessional: React.FC<any> = (props) => {
  const user: any = getItemFromLocalStorage('user');
  const campaignId: string = props.match.params.campaignId;
  const [open, setOpen] = React.useState(false);
  const {
    sheets,
    sheetsFilter,
    sheetSlected,
    // reference,
    // gender,
    // size,
    // weight,
    // age,
    // personalMedicalHistory,
    // familyHistoryOfKidneyDisease,
    // maximumBloodPressure,
    // minimumBloodPressure,
    // protein,
    // blood,
    // glucose,
    // medicalTreatment,
    // medicalTreatmentChecked,
    search,
    index,
    messageError,
    loading,
  } = props;

  console.log('sheetSlected = ', sheetSlected);
  React.useEffect(() => {
    props.screeningSheetProfessionalGetSheets(campaignId);
    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'search') {
      props.screeningSheetProfessionalFilterSheets(search, sheetsFilter);
    }
    props.screeningSheetProfessionalPropsChanged(
      event.target.name,
      event.target.value
    );
  };

  const handleSelectChange = (prop: any) => (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    props.screeningSheetProfessionalPropsChanged(prop, event.target.value);
  };

  const handleRadioPersonalMedicalHistoryChange = (
    prop: keyof PersonalMedicalHistoryProps
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const isTrue = value === 'true' ? true : false;

    const new_obj = {
      ...props.sheetSlected?.personalMedicalHistory,
      [prop]: isTrue,
    };
    props.screeningSheetProfessionalPropsChanged(
      'personalMedicalHistory',
      new_obj
    );
  };

  const handleRadioFamilyHistoryChange = (prop: keyof FamilyHistoryProps) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = (event.target as HTMLInputElement).value;
    const isTrue = value === 'true' ? true : false;

    const new_obj = {
      ...props.sheetSlected?.familyHistoryOfKidneyDisease,
      [prop]: isTrue,
    };
    props.screeningSheetProfessionalPropsChanged(
      'familyHistoryOfKidneyDisease',
      new_obj
    );
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    props.screeningSheetProfessionalPropsChanged(
      event.target.name,
      event.target.checked
    );
  };

  const handleCheckboxProteinChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let new_array: Array<ProteinProps> = Object.assign(
      [],
      props.sheetSlected?.protein
    );

    new_array.map((item) => {
      if (item.name === event.target.name) {
        item.value = event.target.checked;
      } else {
        item.value = false;
      }
    });

    props.screeningSheetProfessionalPropsChanged('protein', new_array);
  };

  const handleCheckboxBloodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let new_array: Array<BloodProps> = Object.assign(
      [],
      props.sheetSlected.blood
    );

    new_array.map((item) => {
      if (item.name === event.target.name) {
        item.value = event.target.checked;
      } else {
        item.value = false;
      }
    });

    props.screeningSheetProfessionalPropsChanged('blood', new_array);
  };

  const handleCheckboxGlucoseChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let new_array: Array<GlucoseProps> = Object.assign(
      [],
      props.sheetSlected.glucose
    );

    new_array.map((item) => {
      if (item.name === event.target.name) {
        item.value = event.target.checked;
      } else {
        item.value = false;
      }
    });

    props.screeningSheetProfessionalPropsChanged('glucose', new_array);
  };

  const onClickCreateNewSheet = () => {
    props.history.push(`/campaigns/new-screening-sheet/${campaignId}`);
  };

  const handleClickSearch = () => {
    props.screeningSheetProfessionalFilterSheets(search, sheetsFilter);
  };

  const onClickSave = () => {
    props.updateScreeningSheetProfessional(props, campaignId, () => {
      setOpen(true);
    });
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onClickScreeningSheet = (prop: string) => (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const newIndex = prop === 'right' ? index + 1 : index - 1;
    if (newIndex < sheets.length && newIndex >= 0) {
      props.clickScreeningSheet(newIndex);
    }
  };

  if (sheets.length === 0) {
    return (
      <Wrapper style={{ flexDirection: 'column', alignItems: 'center' }}>
        <div className='img-container'>
          <img src={emptyState} alt='empty-state' />
        </div>
        <Text fontWeight={600} fontSize={16} lineHeight={36} color='#373737'>
          La liste des fiches est vide
        </Text>
        <Button
          background='#373737'
          width={160}
          onClick={onClickCreateNewSheet}
        >
          Créer nouvelle fiche
        </Button>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className='search-cont'>
        <div className='input-search'>
          <Input
            value={search}
            type='text'
            name='search'
            placeholder='Rechercher'
            width={350}
            margin='10px 0'
            className='input'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleEventChange(e)
            }
          />
          <Button
            onClick={handleClickSearch}
            width={120}
            background='#373737'
            className='button-search'
          >
            Rechercher
          </Button>
        </div>
        <div className='card-user-cont'>
          {index !== 0 && sheets.length > 0 && (
            <Icon
              onClick={onClickScreeningSheet('left')}
              icon={ic_chevron_left}
              size='60'
              style={{
                color: '#8B929C',
                cursor: 'pointer',
                position: 'absolute',
                left: '-60px',
              }}
            />
          )}

          <div className='card-user'>
            <div className='avater'>
              <Icon
                icon={ic_person_outline}
                size='70'
                style={{
                  color: '#ff7e04',
                }}
              />
            </div>

            <Text fontSize={20} fontWeight={700} lineHeight={30} color='373737'>
              {sheetSlected?.reference?.toUpperCase()}
            </Text>
          </div>
          {index < sheets.length - 1 && sheets.length > 0 && (
            <Icon
              onClick={onClickScreeningSheet('right')}
              icon={ic_chevron_right}
              size='60'
              style={{
                color: '#8B929C',
                cursor: 'pointer',
                position: 'absolute',
                right: '-60px',
              }}
            />
          )}
        </div>

        <Button
          background='#373737'
          width={160}
          onClick={onClickCreateNewSheet}
        >
          Créer nouvelle fiche
        </Button>
      </div>
      <div className='divider'></div>
      {sheetSlected ? (
        <div className='fiche-cont'>
          <div className='card'>
            <Text
              fontSize={16}
              fontWeight={700}
              lineHeight={24}
              color='#373737'
              style={{ alignSelf: 'center' }}
            >
              Fiche de Dépistage maladies rénales
            </Text>
            <Grid
              container
              spacing={3}
              xs={12}
              style={{ marginTop: '20px', marginBottom: '20px' }}
            >
              <Grid item xs={12}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='gender'>Sexe</InputLabel>
                  <Select
                    native
                    value={sheetSlected?.gender}
                    onChange={handleSelectChange('gender')}
                    label='sexe'
                    id='gender'
                  >
                    {GENDER.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel
                    htmlFor='Taille en cm'
                    shrink
                    className='label-input'
                  >
                    Taille en cm
                  </InputLabel>
                  <Input
                    value={sheetSlected?.size}
                    type='text'
                    name='size'
                    placeholder='Taille Cm'
                    margin='10px 0'
                    onChange={handleEventChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel
                    htmlFor='Taille en cm'
                    shrink
                    className='label-input'
                  >
                    Poid en Kg
                  </InputLabel>
                  <Input
                    value={sheetSlected.weight}
                    type='text'
                    name='weight'
                    placeholder='Poid Kg'
                    margin='10px 0'
                    onChange={handleEventChange}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FormControl variant='outlined' fullWidth>
                  <InputLabel htmlFor='age'>Age</InputLabel>
                  <Select
                    native
                    value={sheetSlected.age}
                    onChange={handleSelectChange('age')}
                    label='Age'
                    id='age'
                  >
                    {AGE.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.text}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {sheetSlected.weight && sheetSlected.size && (
                <Grid item xs={5}>
                  <Text color='#373737' fontWeight={500} margin={8}>
                    Indice de masse corporelle (IMC) :
                  </Text>

                  <Input
                    disabled
                    value={calculIMC(sheetSlected.weight, sheetSlected.size)}
                    type='text'
                    name='imc'
                    placeholder='IMC'
                    margin='10px 0'
                    onChange={handleEventChange}
                  />
                </Grid>
              )}
            </Grid>

            <Text
              color='#373737'
              fontWeight={600}
              marginBottom={10}
              marginTop={10}
            >
              Antécédentes médicaux personnels :
            </Text>

            <div className='form-control-checkbox'>
              <Text>Présence d’un diabéte connu : </Text>
              <div>
                <RadioGroup
                  className='form-control-radio'
                  name='presenceOfKnownDiabetes'
                  value={
                    sheetSlected.personalMedicalHistory.presenceOfKnownDiabetes
                  }
                  onChange={handleRadioPersonalMedicalHistoryChange(
                    'presenceOfKnownDiabetes'
                  )}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label='oui'
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label='Non'
                  />
                </RadioGroup>
              </div>
            </div>
            <div className='form-control-checkbox'>
              <Text>Présence d’une maladie rénale connue : </Text>
              <div>
                <RadioGroup
                  className='form-control-radio'
                  name='presenceOfKnownkidneyDisease'
                  value={
                    sheetSlected.personalMedicalHistory
                      .presenceOfKnownkidneyDisease
                  }
                  onChange={handleRadioPersonalMedicalHistoryChange(
                    'presenceOfKnownkidneyDisease'
                  )}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label='oui'
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label='Non'
                  />
                </RadioGroup>
              </div>
            </div>
            <div className='form-control-checkbox'>
              <Text>Présence d’une maladie cardiovasculaire connue : </Text>
              <div>
                <RadioGroup
                  className='form-control-radio'
                  name='presenceOfKnownCardiovascularDisease'
                  value={
                    sheetSlected.personalMedicalHistory
                      .presenceOfKnownCardiovascularDisease
                  }
                  onChange={handleRadioPersonalMedicalHistoryChange(
                    'presenceOfKnownCardiovascularDisease'
                  )}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label='oui'
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label='Non'
                  />
                </RadioGroup>
              </div>
            </div>

            <Text
              color='#373737'
              fontWeight={600}
              marginBottom={10}
              marginTop={25}
            >
              Antécédentes Familiaux de maladie rénale :
            </Text>
            <div className='form-control-checkbox'>
              <Text>Présence d’une Maladie rénale connue : </Text>
              <div>
                <RadioGroup
                  className='form-control-radio'
                  name='familyHistoryOfKidneyDisease'
                  value={
                    sheetSlected.familyHistoryOfKidneyDisease
                      .presenceOfKnownKidneyDisease
                  }
                  onChange={handleRadioFamilyHistoryChange(
                    'presenceOfKnownKidneyDisease'
                  )}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label='oui'
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label='Non'
                  />
                </RadioGroup>
              </div>
            </div>
          </div>
          <div className='card'>
            <Text
              fontSize={16}
              fontWeight={700}
              fontStyle='italic'
              lineHeight={24}
              color='#373737'
              marginBottom={30}
              style={{ alignSelf: 'center' }}
            >
              RESULTATS DU DEPISTAGE
            </Text>
            <Text fontWeight={600} color='#373737'>
              Pression artérielle maximale ( systolique )
            </Text>
            <Input
              value={sheetSlected.maximumBloodPressure}
              type='text'
              name='maximumBloodPressure'
              onChange={handleEventChange}
              // onKeyPress={}
              margin='10px 0 20px 0'
            />
            <Text fontWeight={600} color='#373737'>
              Pression artérielle minimale (Diastilique )
            </Text>
            <Input
              value={sheetSlected.minimumBloodPressure}
              type='text'
              name='minimumBloodPressure'
              onChange={handleEventChange}
              // onKeyPress={}
              margin='10px 0'
            />
          </div>
          <div className='card'>
            <Text fontWeight={600} color='#373737' width={110} marginTop={12}>
              Protéines :
            </Text>

            <FormGroup className='form-group'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheetSlected.protein[0].value}
                    onChange={handleCheckboxProteinChange}
                    name='NEGATIVE_OR_TRACES'
                  />
                }
                label='négatif ou traces'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheetSlected.protein[1].value}
                    onChange={handleCheckboxProteinChange}
                    name='≥0,3G/L'
                  />
                }
                label='≥ 0,3 g/l'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheetSlected.protein[2].value}
                    onChange={handleCheckboxProteinChange}
                    name='≥1G/L'
                  />
                }
                label='≥ 1g/l'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheetSlected.protein[3].value}
                    onChange={handleCheckboxProteinChange}
                    name='≥3G/L'
                  />
                }
                label='≥ 3 g/l'
              />
            </FormGroup>

            <Text fontWeight={600} color='#373737' width={110} marginTop={12}>
              Sang :
            </Text>
            <FormGroup className='form-group'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheetSlected.blood[0].value}
                    onChange={handleCheckboxBloodChange}
                    name='NEGATIVE_OR_TRACES'
                  />
                }
                label='négatif ou traces'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheetSlected.blood[1].value}
                    onChange={handleCheckboxBloodChange}
                    name='1_CROIX'
                  />
                }
                label='≥ 1 croix (25 g/mm&#179;)'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheetSlected.blood[2].value}
                    onChange={handleCheckboxBloodChange}
                    name='2_CROIX'
                  />
                }
                label='≥ 2 croix (80 g/mm&#179;)'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheetSlected.blood[3].value}
                    onChange={handleCheckboxBloodChange}
                    name='3_CROIX'
                  />
                }
                label='≥ 3 croix (80 g/mm&#179;)'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheetSlected.blood[4].value}
                    onChange={handleCheckboxBloodChange}
                    name='ANTICOAGULANT_TREATMENT'
                  />
                }
                label='Traitement anticoagulant'
              />
            </FormGroup>

            <Text fontWeight={600} color='#373737' width={110} marginTop={12}>
              Glucose :
            </Text>
            <FormGroup className='form-group'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheetSlected.glucose[0].value}
                    onChange={handleCheckboxGlucoseChange}
                    name='NEGATIVE_OR_TRACES'
                  />
                }
                label='négatif ou traces'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheetSlected.glucose[1].value}
                    onChange={handleCheckboxGlucoseChange}
                    name='1_CROIX'
                  />
                }
                label='≥ 1 croix (2,5 g/l) ou (14 mmol/l)'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheetSlected.glucose[2].value}
                    onChange={handleCheckboxGlucoseChange}
                    name='2_CROIX'
                  />
                }
                label='≥ 2 croix (5 g/l) ou (28 mmol/l)'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sheetSlected.glucose[3].value}
                    onChange={handleCheckboxGlucoseChange}
                    name='3_CROIX'
                  />
                }
                label='≥ 3 croix (10 g/l) ou (55 mmol/l)'
              />
            </FormGroup>
          </div>

          <FormControlLabel
            className='form-control-label'
            control={
              <Checkbox
                checked={sheetSlected.medicalTreatmentChecked}
                onChange={handleCheckboxChange}
                name='medicalTreatmentChecked'
              />
            }
            label='Nécessite un suivi médical'
          />

          {sheetSlected.medicalTreatmentChecked && (
            <div className='card'>
              <Text
                fontSize={16}
                fontWeight={700}
                lineHeight={24}
                color='#373737'
                marginBottom={30}
                style={{ alignSelf: 'center' }}
              >
                Votre suivi médical préconisé
              </Text>
              <InputTextArea
                height={200}
                value={sheetSlected.medicalTreatment}
                type='text'
                name='medicalTreatment'
                placeholder='Description du suivi médical'
                margin='10px 0'
                onChange={handleEventChange}
              ></InputTextArea>
            </div>
          )}

          {messageError && (
            <Text error style={{ alignSelf: 'flex-start' }}>
              {messageError}
            </Text>
          )}

          <Snackbar open={open} autoHideDuration={3500} onClose={handleClose}>
            <Alert onClose={handleClose} severity='success'>
              La feuille de dépistage envoyée avec succès
            </Alert>
          </Snackbar>

          <div className='fiche-cont-footer'>
            <PDFDownloadLink
              document={
                <PdfDocument
                  permission={user.permission}
                  reference={sheetSlected.reference}
                  gender={sheetSlected.gender}
                  size={sheetSlected.size}
                  weight={sheetSlected.weight}
                  age={sheetSlected.age}
                  personalMedicalHistory={sheetSlected.personalMedicalHistory}
                  familyHistoryOfKidneyDisease={
                    sheetSlected.familyHistoryOfKidneyDisease
                  }
                  maximumBloodPressure={sheetSlected.maximumBloodPressure}
                  minimumBloodPressure={sheetSlected.minimumBloodPressure}
                  protein={sheetSlected.protein}
                  blood={sheetSlected.blood}
                  glucose={sheetSlected.glucose}
                  medicalTreatment={sheetSlected.medicalTreatment}
                />
              }
              fileName={`fiche_number_${sheetSlected.reference}.pdf`}
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '80px',
                color: '#fff',
                backgroundColor: '#373737',
                height: 'auto',
                textDecoration: 'none',
                borderRadius: '30px',
                padding: '12px 30px 12px 30px',
                margin: '5px',
                fontStyle: 'normal',
                fontSize: '12px',
              }}
            >
              {({ loading }) => (loading ? 'Loading document...' : 'Imprimer')}
            </PDFDownloadLink>

            <Button onClick={onClickSave}>
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                'Enregistrer'
              )}
            </Button>
          </div>
        </div>
      ) : (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          width={1}
        >
          <Typography variant='body2'>Sélectionnez une fiche</Typography>
        </Box>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => screeningSheetProfessionalStyles()}
`;

const mapStateToProps = ({ screeningSheetProfessional }: RootState) => {
  return {
    sheets: screeningSheetProfessional.sheets,
    sheetsFilter: screeningSheetProfessional.sheets,
    sheetSlected: screeningSheetProfessional.sheetSlected,
    index: screeningSheetProfessional.index,
    search: screeningSheetProfessional.search,
    // reference: screeningSheetProfessional.reference,
    // gender: screeningSheetProfessional.gender,
    // size: screeningSheetProfessional.size,
    // weight: screeningSheetProfessional.weight,
    // age: screeningSheetProfessional.age,
    // personalMedicalHistory: screeningSheetProfessional.personalMedicalHistory,
    // familyHistoryOfKidneyDisease:
    // screeningSheetProfessional.familyHistoryOfKidneyDisease,
    // maximumBloodPressure: screeningSheetProfessional.maximumBloodPressure,
    // minimumBloodPressure: screeningSheetProfessional.minimumBloodPressure,
    // protein: screeningSheetProfessional.protein,
    // blood: screeningSheetProfessional.blood,
    // glucose: screeningSheetProfessional.glucose,
    // medicalTreatment: screeningSheetProfessional.medicalTreatment,
    // medicalTreatmentChecked: screeningSheetProfessional.medicalTreatmentChecked,
    messageError: screeningSheetProfessional.messageError,
    loading: screeningSheetProfessional.loading,
  };
};

export const connector = connect(mapStateToProps, {
  screeningSheetProfessionalGetSheets,
  screeningSheetProfessionalGetSheetById,
  screeningSheetProfessionalPropsChanged,
  screeningSheetProfessionalInitialState,
  updateScreeningSheetProfessional,
  clickScreeningSheet,
  screeningSheetProfessionalFilterSheets,
});

export default connector(ScreeningSheetProfessional);
