import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// material-ui
import Modal from '@material-ui/core/Modal';
// styles
// import { useStyles } from './modal.styles';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';

type ModalProps = {
  open: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  minWidth?: string | number;
  width?: string | number;
  left?: string | number;
  height?: string | number;
  top?: string | number;
};

function getModalStyle() {
  const top = 52;
  const left = 57;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: 'auto',
      position: 'absolute',
      width: 'fit-content',
      height: 'fit-content',
      // maxWidth: '650px',
      minWidth: '420px',
      maxHeight: '640px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      // border: '2px solid #000',
      boxShadow: ' 0px 0px 20px 2px rgba(55, 55, 55, 0.1)',
      padding: '25px',
      overflowY: 'auto',
    },
  })
);

const CustmerModal: React.FC<ModalProps> = ({ open, onClose, children }) => {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();

  //   const handleClickAway = () => {
  //     onClose && onClose();
  //   };

  return (
    <Modal
      // disableEnforceFocus
      aria-labelledby='modal-information'
      aria-describedby='modal-information'
      open={open}
      onClose={onClose}
    >
      <div style={modalStyle} className={classes.paper}>
        {children}
      </div>
    </Modal>
  );
};

export default React.memo(CustmerModal);

{
  /* <div className={classes.boxContainer}> */
}
{
  /* <ClickAwayListener onClickAway={handleClickAway}> */
}
// <div className={classes.mainContainer}>{children}</div>
{
  /* </ClickAwayListener> */
}
{
  /* </div> */
}
