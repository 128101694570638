import {
  SCREENING_SHEET_PROFESSIONAL_GET_SHEETS_SUCCESS,
  SCREENING_SHEET_PROFESSIONAL_GET_SHEETS_FAILED,
  SCREENING_SHEET_PROFESSIONAL_GET_SCREENING_SHEET_SUCCESS,
  SCREENING_SHEET_PROFESSIONAL_GET_SCREENING_SHEET_FAILED,
  SCREENING_SHEET_PROFESSIONAL_PROPS_CHANGED,
  UPDATE_SCREENING_SHEET_PROFESSIONAL_SUCCESS,
  UPDATE_SCREENING_SHEET_PROFESSIONAL_FAILED,
  GET_SCREENING_SHEET_PROFESSIONAL,
  SCREENING_SHEET_PROFESSIONAL_INITIAL_STATE,
  SCREENING_SHEET_PROFESSIONAL_PROPS_VALIDATION,
  SCREENING_SHEET_PROFESSIONAL_FILTER_SHEETS,
} from '../actions/types';
import {
  ScreeningSheetProfessionalState,
  ScreeningSheetProfessionalActionTypes,
} from '../modules/ScreeningSheetProfessional/screeningSheetProfessional.types';

const INITIAL_STATE: ScreeningSheetProfessionalState = {
  sheets: [],
  sheetsFilter: [],
  sheetSlected: {
    _id: null,
    reference: '----------',
    gender: 'male',
    size: '',
    weight: '',
    age: '≤18',
    personalMedicalHistory: {
      presenceOfKnownDiabetes: false,
      presenceOfKnownkidneyDisease: false,
      presenceOfKnownCardiovascularDisease: false,
    },
    familyHistoryOfKidneyDisease: {
      presenceOfKnownKidneyDisease: false,
    },
    maximumBloodPressure: '',
    minimumBloodPressure: '',
    protein: [
      { name: 'NEGATIVE_OR_TRACES', value: false },
      { name: '≥0,3G/L', value: false },
      { name: '≥1G/L', value: false },
      { name: '≥3G/L', value: false },
    ],
    blood: [
      { name: 'NEGATIVE_OR_TRACES', value: false },
      { name: '1_CROIX', value: false },
      { name: '2_CROIX', value: false },
      { name: '3_CROIX', value: false },
      { name: 'ANTICOAGULANT_TREATMENT', value: false },
    ],

    glucose: [
      { name: 'NEGATIVE_OR_TRACES', value: false },
      { name: '1_CROIX', value: false },
      { name: '2_CROIX', value: false },
      { name: '3_CROIX', value: false },
    ],
    medicalTreatment: '',
    medicalTreatmentChecked: false,
  },
  index: 0,
  search: '',
  messageError: '',
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: ScreeningSheetProfessionalActionTypes
): ScreeningSheetProfessionalState => {
  switch (action.type) {
    case GET_SCREENING_SHEET_PROFESSIONAL:
      return {
        ...state,
        index: action.payload,
        sheetSlected: {
          ...state.sheets[action.payload],
          medicalTreatmentChecked:
            state.sheets[action.payload]?.medicalTreatment.trim().length !==
              0 || false,
        },
      };

    case SCREENING_SHEET_PROFESSIONAL_GET_SHEETS_SUCCESS: {
      const sheetSlected =
        action.payload.length > 0
          ? {
              ...action.payload[state.index],
              medicalTreatmentChecked:
                action.payload[state.index]?.medicalTreatment.trim().length !==
                  0 || false,
            }
          : state.sheetSlected;
      return {
        ...state,
        sheets: action.payload,
        sheetsFilter: action.payload,
        sheetSlected,
      };
    }

    case SCREENING_SHEET_PROFESSIONAL_GET_SHEETS_FAILED:
      return { ...state, messageError: action.payload };

    case SCREENING_SHEET_PROFESSIONAL_FILTER_SHEETS: {
      console.log('aciton payload ', action.payload);
      return {
        ...state,
        search: action.search,
        sheetsFilter: action.payload,
        sheetSlected:
          action.payload.length > 0
            ? {
                ...action.payload[0],
                medicalTreatmentChecked:
                  action.payload[0]?.medicalTreatment.trim().length !== 0 ||
                  false,
              }
            : null,
      };
    }

    case SCREENING_SHEET_PROFESSIONAL_GET_SCREENING_SHEET_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case SCREENING_SHEET_PROFESSIONAL_GET_SCREENING_SHEET_FAILED:
      return { ...state, messageError: action.payload };

    case SCREENING_SHEET_PROFESSIONAL_PROPS_CHANGED: {
      if (action.prop === 'search' || action.prop === 'loading') {
        return { ...state, [action.prop]: action.value };
      } else {
        return {
          ...state,
          sheetSlected: { ...state.sheetSlected, [action.prop]: action.value },
        };
      }
    }

    case SCREENING_SHEET_PROFESSIONAL_PROPS_VALIDATION:
      return { ...state, ...action.payload, loading: false };

    case UPDATE_SCREENING_SHEET_PROFESSIONAL_SUCCESS:
      return {
        ...state,
        sheetSlected: {
          ...action.payload,
          medicalTreatmentChecked:
            action.payload?.medicalTreatment.trim().length !== 0 || false,
        },
        loading: false,
      };

    case UPDATE_SCREENING_SHEET_PROFESSIONAL_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
      };

    case SCREENING_SHEET_PROFESSIONAL_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
