import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Avatar, CircularProgress, FormLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

//Icons
import { Icon } from 'react-icons-kit';
import { ic_person_outline } from 'react-icons-kit/md/ic_person_outline'; //Styles
import styled from 'styled-components';
import { screeningSheetStyles } from './createScreeningSheet.styles';
//Types
import {
  CreateScreeningSheetProps,
  FamilyHistoryProps,
  PersonalMedicalHistoryProps,
  CreateScreeningSheetState,
  ProteinProps,
  BloodProps,
  GlucoseProps,
} from './createScreeningSheet.types';
//Commons
import { Text, Input, Button, InputTextArea } from '../../commons';
import NavBar from '../../components/NavBar';
import { Fragment } from 'react';
//Components
import PdfDocument from '../../components/PdfDocument';
//Modules

//Assets/Images

//Actions
import {
  createScreeningSheetPropsChanged,
  createScreeningSheetInitialState,
  createScreeningSheet,
} from '../../actions/createScreeningSheetActions';

//Reducers
import { RootState } from '../../reducers';
import { getItemFromLocalStorage } from '../../utils/storage';

//Utils
import { calculIMC } from './createScreeningSheet.utils';
// import { getItemFromLocalStorage } from '../../utils/storage';

const AGE = [
  {
    id: 1,
    text: '<= 18 ans',
    value: '≤18',
  },
  {
    id: 2,
    text: '18 - 40 ans',
    value: '18-40',
  },
  {
    id: 3,
    text: '41 - 60 ans',
    value: '41-60',
  },
  {
    id: 4,
    text: '> 60 ans',
    value: '>60',
  },
  {
    id: 5,
    text: '> 70 ans',
    value: '>70',
  },
  {
    id: 6,
    text: '> 80 ans',
    value: '>80',
  },
];

const GENDER = [
  {
    id: 1,
    text: 'Homme',
    value: 'male',
  },
  {
    id: 2,
    text: 'Femme',
    value: 'female',
  },
];

const CreateScreeningSheet: React.FC<CreateScreeningSheetProps> = (props) => {
  const user: any = getItemFromLocalStorage('user');
  const campaignId: string = props.match.params.campaignId;
  const [open, setOpen] = React.useState(false);
  const [isReady, setIsReady] = React.useState(false);
  const {
    reference,
    gender,
    size,
    weight,
    age,
    personalMedicalHistory,
    familyHistoryOfKidneyDisease,
    maximumBloodPressure,
    minimumBloodPressure,
    protein,
    blood,
    glucose,
    medicalTreatment,
    medicalTreatmentChecked,
    messageError,
    loading,
    disabled,
    disabledButtonSave,
  } = props;

  React.useEffect(() => {
    if (!disabled) {
      setTimeout(() => {
        setIsReady(true);
      }, 500);
    }
  }, [disabled]);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.createScreeningSheetPropsChanged(
      event.target.name,
      event.target.value
    );
  };

  const handleSelectChange = (prop: keyof CreateScreeningSheetState) => (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    props.createScreeningSheetPropsChanged(prop, event.target.value);
  };

  const handleRadioPersonalMedicalHistoryChange = (
    prop: keyof PersonalMedicalHistoryProps
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const isTrue = value === 'true' ? true : false;

    const new_obj = { ...props.personalMedicalHistory, [prop]: isTrue };
    props.createScreeningSheetPropsChanged('personalMedicalHistory', new_obj);
  };

  const handleRadioFamilyHistoryChange = (prop: keyof FamilyHistoryProps) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = (event.target as HTMLInputElement).value;
    const isTrue = value === 'true' ? true : false;

    const new_obj = { ...props.familyHistoryOfKidneyDisease, [prop]: isTrue };
    props.createScreeningSheetPropsChanged(
      'familyHistoryOfKidneyDisease',
      new_obj
    );
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setState({ ...state, [event.target.name]: event.target.checked });
    props.createScreeningSheetPropsChanged(
      event.target.name,
      event.target.checked
    );
  };

  const handleCheckboxProteinChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let new_array: Array<ProteinProps> = Object.assign([], props.protein);

    // const new_obj = new_array.find(
    //   (item: ProteinProps) => item.name === event.target.name
    // );

    // if (new_obj) {
    //   new_obj.value = event.target.checked;
    // }
    new_array.map((item) => {
      if (item.name === event.target.name) {
        item.value = event.target.checked;
      } else {
        item.value = false;
      }
    });

    props.createScreeningSheetPropsChanged('protein', new_array);
  };

  const handleCheckboxBloodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let new_array: Array<BloodProps> = Object.assign([], props.blood);

    new_array.map((item) => {
      if (item.name === event.target.name) {
        item.value = event.target.checked;
      } else {
        item.value = false;
      }
    });

    props.createScreeningSheetPropsChanged('blood', new_array);
  };

  const handleCheckboxGlucoseChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let new_array: Array<GlucoseProps> = Object.assign([], props.glucose);

    new_array.map((item) => {
      if (item.name === event.target.name) {
        item.value = event.target.checked;
      } else {
        item.value = false;
      }
    });

    props.createScreeningSheetPropsChanged('glucose', new_array);
  };

  const onClickCreateNewSheet = () => {
    props.createScreeningSheetInitialState();
  };

  const onClickSrenningSheetProfessional = () => {
    props.history.push(`/campaigns/screening-sheet-professional/${campaignId}`);
  };

  const onClickSave = () => {
    props.createScreeningSheet(props, campaignId, () => {
      setOpen(true);
    });
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const renderButtonPdfDocument = () => {
    if (isReady) {
      return (
        <PDFDownloadLink
          document={
            <PdfDocument
              permission={user.permission}
              reference={reference}
              gender={gender}
              size={size}
              weight={weight}
              age={age}
              personalMedicalHistory={personalMedicalHistory}
              familyHistoryOfKidneyDisease={familyHistoryOfKidneyDisease}
              maximumBloodPressure={maximumBloodPressure}
              minimumBloodPressure={minimumBloodPressure}
              protein={protein}
              blood={blood}
              glucose={glucose}
              medicalTreatment={medicalTreatment}
            />
          }
          fileName={`fiche_number_${reference}.pdf`}
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '80px',
            color: '#fff',
            backgroundColor: '#373737',
            height: 'auto',
            textDecoration: 'none',
            borderRadius: '30px',
            padding: '12px 30px 12px 30px',
            margin: '5px',
            fontStyle: 'normal',
            fontSize: '12px',
          }}
        >
          {({ loading }) => (loading ? 'Loading document...' : 'Imprimer')}
        </PDFDownloadLink>
      );
    }
  };

  return (
    <Wrapper>
      <div className='search-cont'>
        <div className='card-user'>
          <div className='avater'>
            <Icon
              icon={ic_person_outline}
              size='70'
              style={{
                color: '#ff7e04',
              }}
            />
          </div>
          <Text fontSize={20} fontWeight={700} lineHeight={30} color='373737'>
            {reference.toUpperCase()}
          </Text>
        </div>

        {!disabled && (
          <Button
            background='#373737'
            width={160}
            onClick={onClickCreateNewSheet}
          >
            Créer nouvelle fiche
          </Button>
        )}

        {user.permission === 'professional' && (
          <Button
            background='#FF7E04'
            width={160}
            onClick={onClickSrenningSheetProfessional}
          >
            voir Fiches
          </Button>
        )}
      </div>
      <div className='divider'></div>
      <div className='fiche-cont'>
        <div className='card'>
          <Text
            fontSize={16}
            fontWeight={700}
            lineHeight={24}
            color='#373737'
            style={{ alignSelf: 'center' }}
          >
            Fiche de Dépistage maladies rénales
          </Text>
          <Grid
            container
            spacing={3}
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            <Grid item xs={12}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel htmlFor='gender'>Sexe</InputLabel>
                <Select
                  native
                  value={gender}
                  onChange={handleSelectChange('gender')}
                  label='sexe'
                  id='gender'
                >
                  {GENDER.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel
                  htmlFor='Taille en cm'
                  shrink
                  className='label-input'
                >
                  Taille en cm
                </InputLabel>
                <Input
                  value={size}
                  type='text'
                  name='size'
                  margin='10px 0'
                  onChange={handleEventChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel
                  htmlFor='Taille en cm'
                  shrink
                  className='label-input'
                >
                  Poid en Kg
                </InputLabel>
                <Input
                  value={weight}
                  type='text'
                  name='weight'
                  margin='10px 0'
                  onChange={handleEventChange}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FormControl variant='outlined' fullWidth>
                <InputLabel htmlFor='age'>Age</InputLabel>
                <Select
                  native
                  value={age}
                  onChange={handleSelectChange('age')}
                  label='Age'
                  id='age'
                >
                  {AGE.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {weight && size && (
              <Grid item xs={5}>
                <Text color='#373737' fontWeight={500} margin={8}>
                  Indice de masse corporelle (IMC) :
                </Text>
                {/* <Text color='#dd0089' fontWeight={600} margin={8}>
                    {calculIMC(weight, size)}
                    </Text> */}
                <Input
                  disabled
                  value={calculIMC(weight, size)}
                  type='text'
                  name='imc'
                  placeholder='IMC'
                  margin='10px 0'
                  onChange={handleEventChange}
                />
              </Grid>
            )}
          </Grid>

          <Text
            color='#373737'
            fontWeight={600}
            marginBottom={10}
            marginTop={10}
          >
            Antécédentes médicaux personnels :
          </Text>

          <div className='form-control-checkbox'>
            <Text>Présence d’un diabéte connu : </Text>
            <div>
              <RadioGroup
                className='form-control-radio'
                name='presenceOfKnownDiabetes'
                value={personalMedicalHistory.presenceOfKnownDiabetes}
                onChange={handleRadioPersonalMedicalHistoryChange(
                  'presenceOfKnownDiabetes'
                )}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label='oui'
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label='Non'
                />
              </RadioGroup>
            </div>
          </div>
          <div className='form-control-checkbox'>
            <Text>Présence d’une maladie rénale connue : </Text>
            <div>
              <RadioGroup
                className='form-control-radio'
                name='presenceOfKnownkidneyDisease'
                value={personalMedicalHistory.presenceOfKnownkidneyDisease}
                onChange={handleRadioPersonalMedicalHistoryChange(
                  'presenceOfKnownkidneyDisease'
                )}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label='oui'
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label='Non'
                />
              </RadioGroup>
            </div>
          </div>
          <div className='form-control-checkbox'>
            <Text>Présence d’une maladie cardiovasculaire connue : </Text>
            <div>
              <RadioGroup
                className='form-control-radio'
                name='presenceOfKnownCardiovascularDisease'
                value={
                  personalMedicalHistory.presenceOfKnownCardiovascularDisease
                }
                onChange={handleRadioPersonalMedicalHistoryChange(
                  'presenceOfKnownCardiovascularDisease'
                )}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label='oui'
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label='Non'
                />
              </RadioGroup>
            </div>
          </div>

          <Text
            color='#373737'
            fontWeight={600}
            marginBottom={10}
            marginTop={25}
          >
            Antécédentes Familiaux de maladie rénale :
          </Text>
          <div className='form-control-checkbox'>
            <Text>Présence d’une Maladie rénale connue : </Text>

            <div>
              <RadioGroup
                className='form-control-radio'
                name='familyHistoryOfKidneyDisease'
                value={
                  familyHistoryOfKidneyDisease.presenceOfKnownKidneyDisease
                }
                onChange={handleRadioFamilyHistoryChange(
                  'presenceOfKnownKidneyDisease'
                )}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label='oui'
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label='Non'
                />
              </RadioGroup>
            </div>
          </div>
        </div>
        {user.permission === 'professional' && (
          <React.Fragment>
            <div className='card'>
              <Text
                fontSize={16}
                fontWeight={700}
                lineHeight={24}
                fontStyle='italic'
                color='#373737'
                marginBottom={30}
                style={{ alignSelf: 'center' }}
              >
                RESULTATS DU DEPISTAGE
              </Text>
              <Text fontWeight={600} color='#373737'>
                Pression artérielle maximale (systolique)
              </Text>
              <Input
                value={maximumBloodPressure}
                type='text'
                name='maximumBloodPressure'
                onChange={handleEventChange}
                // onKeyPress={}
                margin='10px 0 20px 0'
              />
              <Text fontWeight={600} color='#373737'>
                Pression artérielle minimale (Diastilique)
              </Text>
              <Input
                value={minimumBloodPressure}
                type='text'
                name='minimumBloodPressure'
                onChange={handleEventChange}
                // onKeyPress={}
                margin='10px 0'
              />
            </div>

            <div className='card'>
              <Text fontWeight={600} color='#373737' width={110} marginTop={12}>
                Protéines :
              </Text>
              {/* <RadioGroup
                className='form-control-radio'
                name='protein'
                onChange={handleCheckboxProteinChange}
              >
                <FormControlLabel
                  value={protein[0].value}
                  control={<Radio />}
                  label='négatif ou traces'
                />
                <FormControlLabel
                  value={protein[1].value}
                  control={<Radio />}
                  label='≥ 0,3 g/l'
                />
                <FormControlLabel
                  value={protein[2].value}
                  control={<Radio />}
                  label='≥ 1g/l'
                />
                <FormControlLabel
                  value={protein[2].value}
                  control={<Radio />}
                  label='≥ 3 g/l'
                />
              </RadioGroup> */}
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={protein[0].value}
                      onChange={handleCheckboxProteinChange}
                      name='NEGATIVE_OR_TRACES'
                    />
                  }
                  label='négatif ou traces'
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={protein[1].value}
                      onChange={handleCheckboxProteinChange}
                      name='≥0,3G/L'
                    />
                  }
                  label='≥ 0,3 g/l'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={protein[2].value}
                      onChange={handleCheckboxProteinChange}
                      name='≥1G/L'
                    />
                  }
                  label='≥ 1g/l'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={protein[3].value}
                      onChange={handleCheckboxProteinChange}
                      name='≥3G/L'
                    />
                  }
                  label='≥ 3 g/l'
                />
              </FormGroup>

              <Text fontWeight={600} color='#373737' width={110} marginTop={12}>
                Sang :
              </Text>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={blood[0].value}
                      onChange={handleCheckboxBloodChange}
                      name='NEGATIVE_OR_TRACES'
                    />
                  }
                  label='négatif ou traces'
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={blood[1].value}
                      onChange={handleCheckboxBloodChange}
                      name='1_CROIX'
                    />
                  }
                  label='≥ 1 croix (25 g/mm&#179;)'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={blood[2].value}
                      onChange={handleCheckboxBloodChange}
                      name='2_CROIX'
                    />
                  }
                  label='≥ 2 croix (80 g/mm&#179;)'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={blood[3].value}
                      onChange={handleCheckboxBloodChange}
                      name='3_CROIX'
                    />
                  }
                  label='≥ 3 croix (80 g/mm&#179;)'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={blood[4].value}
                      onChange={handleCheckboxBloodChange}
                      name='ANTICOAGULANT_TREATMENT'
                    />
                  }
                  label='Traitement anticoagulant'
                />
              </FormGroup>

              <Text fontWeight={600} color='#373737' width={110} marginTop={12}>
                Glucose :
              </Text>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={glucose[0].value}
                      onChange={handleCheckboxGlucoseChange}
                      name='NEGATIVE_OR_TRACES'
                    />
                  }
                  label='négatif ou traces'
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={glucose[1].value}
                      onChange={handleCheckboxGlucoseChange}
                      name='1_CROIX'
                    />
                  }
                  label='≥ 1 croix (2,5 g/l) ou (14 mmol/l)'
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={glucose[2].value}
                      onChange={handleCheckboxGlucoseChange}
                      name='2_CROIX'
                    />
                  }
                  label='≥ 2 croix (5 g/l) ou (28 mmol/l)'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={glucose[3].value}
                      onChange={handleCheckboxGlucoseChange}
                      name='3_CROIX'
                    />
                  }
                  label='≥ 3 croix (10 g/l) ou (55 mmol/l)'
                />
              </FormGroup>
            </div>

            <FormControlLabel
              style={{ width: '730px' }}
              control={
                <Checkbox
                  checked={medicalTreatmentChecked}
                  onChange={handleCheckboxChange}
                  name='medicalTreatmentChecked'
                />
              }
              label='Nécessite un suivi médical'
            />

            {medicalTreatmentChecked && (
              <div className='card'>
                <Text
                  fontSize={16}
                  fontWeight={700}
                  lineHeight={24}
                  color='#373737'
                  marginBottom={30}
                  style={{ alignSelf: 'center' }}
                >
                  Votre suivi médical préconisé
                </Text>
                <InputTextArea
                  height={200}
                  value={medicalTreatment}
                  type='text'
                  name='medicalTreatment'
                  placeholder='Description du suivi médical'
                  margin='10px 0'
                  onChange={handleEventChange}
                ></InputTextArea>
              </div>
            )}
          </React.Fragment>
        )}

        {messageError && <Text error>{messageError}</Text>}

        <Snackbar open={open} autoHideDuration={3500} onClose={handleClose}>
          <Alert onClose={handleClose} severity='success'>
            La feuille de dépistage envoyée avec succès
          </Alert>
        </Snackbar>

        <div className='fiche-cont-footer'>
          {renderButtonPdfDocument()}

          <Button onClick={onClickSave} disabled={disabledButtonSave}>
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              'Enregistrer'
            )}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => screeningSheetStyles()}
`;

const mapStateToProps = ({ createScreeningSheet }: RootState) => {
  return {
    reference: createScreeningSheet.reference,
    gender: createScreeningSheet.gender,
    size: createScreeningSheet.size,
    weight: createScreeningSheet.weight,
    age: createScreeningSheet.age,
    personalMedicalHistory: createScreeningSheet.personalMedicalHistory,
    familyHistoryOfKidneyDisease:
      createScreeningSheet.familyHistoryOfKidneyDisease,
    maximumBloodPressure: createScreeningSheet.maximumBloodPressure,
    minimumBloodPressure: createScreeningSheet.minimumBloodPressure,
    protein: createScreeningSheet.protein,
    blood: createScreeningSheet.blood,
    glucose: createScreeningSheet.glucose,
    medicalTreatment: createScreeningSheet.medicalTreatment,
    medicalTreatmentChecked: createScreeningSheet.medicalTreatmentChecked,
    messageError: createScreeningSheet.messageError,
    loading: createScreeningSheet.loading,
    disabled: createScreeningSheet.disabled,
    disabledButtonSave: createScreeningSheet.disabledButtonSave,
  };
};

export const connector = connect(mapStateToProps, {
  createScreeningSheetPropsChanged,
  createScreeningSheetInitialState,
  createScreeningSheet,
});

export default connector(CreateScreeningSheet);
