import axios from 'axios';
import { URL } from './configApi';
import {
  CAMPAIGNS_GET_LIST_DEPARTMENTS_SUCCESS,
  CAMPAIGNS_GET_LIST_DEPARTMENTS_FAILED,
  CAMPAIGNS_GET_LIST_CAMPAIGNS_SUCCESS,
  CAMPAIGNS_GET_LIST_CAMPAIGNS_FAILED,
  CAMPAIGNS_PROPS_CHANGED,
  AppThunk,
} from './types';

import {
  CampaignsPropsChangedAction,
} from '../modules/Campaigns/campaigns.types';


export const campaignsPropsChanged = (
  prop: string,
  value: any
): CampaignsPropsChangedAction => {
  return { type: CAMPAIGNS_PROPS_CHANGED, prop, value };
};

export const campaignsGetDepartments = (intervenerId:string): AppThunk => (dispatch) => {
  axios
    .get(`${URL}/campagnes/departments/intervener/${intervenerId}`)
    .then(({ data }: { data: any }) => {
      if (data.status === 'success') {
        
        dispatch({
          type: CAMPAIGNS_GET_LIST_DEPARTMENTS_SUCCESS,
          payload: data.response.data,
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: CAMPAIGNS_GET_LIST_DEPARTMENTS_FAILED,
          payload: 'Network Error',
        });
      }
    });
};

export const campaignsGetCampaignsByDepartment = (departmentId:string): AppThunk => (dispatch) => {
  axios
    .get(`${URL}/campagnes/department/${departmentId}`)
    .then(({ data }: { data: any }) => {
      if (data.status === 'success') {     
        dispatch({
          type: CAMPAIGNS_GET_LIST_CAMPAIGNS_SUCCESS,
          campaigns: data.response.data.campagnes,
          departmentDetail: data.response.data.department[0],
        });
        }
    })
    .catch((error) => {
      console.log(error.response);
      
      // if (error.response.status === 500) {
      //   dispatch({
      //     type: CAMPAIGNS_GET_LIST_CAMPAIGNS_FAILED,
      //     payload: 'Network Error',
      //   });
      // }
    });
};










