import {
  LOGIN_PROPS_CHANGED,
  LOGIN_PROPS_VALIDATION,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_INITIAL_STATE,
} from '../actions/types';
import { LoginActionTypes, LoginState } from '../modules/Login/login.types';

const INITIAL_STATE: LoginState = {
  email: '',
  password: '',
  emailError: '',
  passwordError: '',
  messageError: '',
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: LoginActionTypes
): LoginState => {
  switch (action.type) {
    case LOGIN_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case LOGIN_PROPS_VALIDATION:
      return { ...state, ...action.payload, loading: false };
    case LOGIN_SUCCESS:
      return { ...state, messageError: '', loading: false };
    case LOGIN_FAILED:
      return { ...state, messageError: action.payload, loading: false };
    case LOGIN_INITIAL_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
