import { isEmpty, isEqual } from '../../utils/validations';
import { ResetPasswordState } from './resetPassword.types';

export const validateFieldsResetPassword = (state: ResetPasswordState) => {
  let newProps: ResetPasswordState = Object.assign({}, state);

  if (isEmpty(newProps.password)) {
    newProps.passwordError = 'Nouveau mot de passe est obligatoire.';
  } else if (newProps.password.length < 4) {
    newProps.passwordError =
      'Le mot de passe doit contenir au moins 4 caractères.';
  } else {
    newProps.passwordError = '';
  }

  if (isEmpty(newProps.passwordConfirm)) {
    newProps.passwordConfirmError = 'Confirm mot de passe est obligatoire.';
  } else if (!isEqual(newProps.password, newProps.passwordConfirm)) {
    newProps.passwordConfirmError =
      'La confirmation ne correspond pas au nouveau mot de passe.';
  } else {
    newProps.passwordConfirmError = '';
  }

  let valid = false;
  if (newProps.passwordError === '' && newProps.passwordConfirmError === '') {
    valid = true;
  }
  return { newProps, valid };
};
