import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { RootState } from '../reducers';

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// LOGIN
export const LOGIN_PROPS_CHANGED = 'login_props_changed';
export const LOGIN_PROPS_VALIDATION = 'login_props_validation';
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_FAILED = 'login_failed';
export const LOGIN_INITIAL_STATE = 'login_initial_state';

// FORGOT PASSWORD
export const FORGOT_PASSWORD_PROPS_CHANGED = 'forgot_password_props_changed';
export const FORGOT_PASSWORD_SUCCESS = 'forgot_password_success';
export const FORGOT_PASSWORD_FAILED = 'forgot_password_failed';
export const FORGOT_PASSWORD_INITIAL_STATE = 'forgot_password_initial_state';

// RESET PASSWORD
export const RESET_PASSWORD_PROPS_CHANGED = 'reset_password_props_changed';
export const RESET_PASSWORD_PROPS_VALIDATION =
  'reset_password_props_validation';
export const RESET_PASSWORD_VERIFICATION_TOKEN_FAILED =
  'reset_password_verification_token_failed';
export const RESET_PASSWORD_VERIFICATION_TOKEN_SUCCESS =
  'reset_password_verification_token_success';
export const RESET_PASSWORD_SUCCESS = 'reset_password_success';
export const RESET_PASSWORD_FAILED = 'reset_password_failed';
export const RESET_PASSWORD_INITIAL_STATE = 'reset_password_initial_state';

// CAMPAIGNS
export const CAMPAIGNS_PROPS_CHANGED = 'campaigns_props_changed';
export const CAMPAIGNS_GET_LIST_DEPARTMENTS_SUCCESS =
  'campaigns_get_list_departments_success';
export const CAMPAIGNS_GET_LIST_DEPARTMENTS_FAILED =
  'campaigns_get_list_departments_failed';
export const CAMPAIGNS_GET_LIST_CAMPAIGNS_SUCCESS =
  'campaigns_get_list_campaigns_success';
export const CAMPAIGNS_GET_LIST_CAMPAIGNS_FAILED =
  'campaigns_get_list_campaigns_failed';

// CREATE SCREEN SHEET  
export const CREATE_SCREENING_SHEET_PROPS_CHANGED =
  'create_screening_sheet_props_changed';
export const CREATE_SCREENING_SHEET_INITIAL_STATE =
  'create_screening_sheet_initial_state';
export const CREATE_SCREENING_SHEET_SUCCESS =
  'create_screening_sheet_success';
export const CREATE_SCREENING_SHEET_FAILED =
  'create_screening_sheet_failed';
export const CREATE_SCREENING_SHEET_PROPS_VALIDATION =
  'create_screening_sheet_props_validation';

//SCREEN SHEET PROFESSIONAL
export const SCREENING_SHEET_PROFESSIONAL_GET_SHEETS_SUCCESS =
  'screening_sheet_professional_get_sheets_success';
export const SCREENING_SHEET_PROFESSIONAL_GET_SHEETS_FAILED =
  'screening_sheet_professional_get_sheets_failed';

export const SCREENING_SHEET_PROFESSIONAL_GET_SCREENING_SHEET_SUCCESS =
  'screening_sheet_professional_get_screening_sheet_success';
export const SCREENING_SHEET_PROFESSIONAL_GET_SCREENING_SHEET_FAILED =
  'screening_sheet_professional_get_screening_sheet_failed';
export const GET_SCREENING_SHEET_PROFESSIONAL =
  'get_screening_sheet_professional';
export const SCREENING_SHEET_PROFESSIONAL_PROPS_CHANGED =
  'screening_sheet_professional_props_changed';
export const UPDATE_SCREENING_SHEET_PROFESSIONAL_SUCCESS =
  'update_screening_sheet_professional_success';
export const UPDATE_SCREENING_SHEET_PROFESSIONAL_FAILED =
  'update_screening_sheet_professional_failed';
export const SCREENING_SHEET_PROFESSIONAL_PROPS_VALIDATION =
  'screening_sheet_professional_props_validation';
export const SCREENING_SHEET_PROFESSIONAL_FILTER_SHEETS =
  'screening_sheet_professional_filter_sheets';
export const SCREENING_SHEET_PROFESSIONAL_INITIAL_STATE =
  'screening_sheet_professional_initial_state';




