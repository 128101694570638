import styled from 'styled-components';
import { textStyles } from './text.styles';

export type TextProps = {
  error?: boolean;
  fontStyle?: string;
  fontWeight?: number;
  fontSize?: number;
  lineHeight?: number;
  color?: string;
  margin?: number;
  marginTop?: number;
  marginBottom?: number;
  width?: number;
  onClick?: () => void;
};

const Text = styled.span<TextProps>`
  ${(props) => textStyles(props)}
`;

export default Text;
