import {
  CREATE_SCREENING_SHEET_PROPS_CHANGED,
  CREATE_SCREENING_SHEET_SUCCESS,
  CREATE_SCREENING_SHEET_FAILED,
  CREATE_SCREENING_SHEET_INITIAL_STATE,
  CREATE_SCREENING_SHEET_PROPS_VALIDATION,
} from '../actions/types';
import {
  CreateScreeningSheetState,
  CreateScreeningSheetActionTypes,
  ProteinProps,
  BloodProps,
  GlucoseProps,
} from '../modules/CreateScreeningSheet/createScreeningSheet.types';

const INITIAL_STATE: CreateScreeningSheetState = {
  reference: '----------',
  gender: 'male',
  size: '',
  weight: '',
  age: '≤18',
  personalMedicalHistory: {
    presenceOfKnownDiabetes: false,
    presenceOfKnownkidneyDisease: false,
    presenceOfKnownCardiovascularDisease: false,
  },
  familyHistoryOfKidneyDisease: {
    presenceOfKnownKidneyDisease: false,
  },
  maximumBloodPressure: '',
  minimumBloodPressure: '',
  protein: [
    { name: 'NEGATIVE_OR_TRACES', value: false, },
    { name: '≥0,3G/L', value: false,  },
    { name: '≥1G/L', value: false, },
    { name: '≥3G/L', value: false , },
  ],
  blood: [
    { name: 'NEGATIVE_OR_TRACES', value: false },
    { name: '1_CROIX', value: false },
    { name: '2_CROIX', value: false },
    { name: '3_CROIX', value: false },
    { name: 'ANTICOAGULANT_TREATMENT', value: false },
  ],

  glucose: [
    { name: 'NEGATIVE_OR_TRACES', value: false },
    { name: '1_CROIX', value: false },
    { name: '2_CROIX', value: false },
    { name: '3_CROIX', value: false },
  ],
  medicalTreatment: '',
  medicalTreatmentChecked: false,
  messageError: '',
  loading: false,
  disabled: true,
  disabledButtonSave: false,
};

export default (
  state = INITIAL_STATE,
  action: CreateScreeningSheetActionTypes
): CreateScreeningSheetState => {
  switch (action.type) {
    case CREATE_SCREENING_SHEET_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case CREATE_SCREENING_SHEET_PROPS_VALIDATION:
      return { ...state, ...action.payload, loading: false };

    case CREATE_SCREENING_SHEET_SUCCESS:
      return {
        ...state, ...action.payload,
        loading: false, disabled: false, disabledButtonSave: true
      };

    case CREATE_SCREENING_SHEET_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
      };

    case CREATE_SCREENING_SHEET_INITIAL_STATE: {

      let proteinInitialState: Array<ProteinProps> = Object.assign(
        [],
        state.protein
      );

      proteinInitialState.map((item) => {
        item.value = false
      })

      let bloodInitialState: Array<BloodProps> = Object.assign(
        [],
        state.blood
      );

      bloodInitialState.map((item) => {
        item.value = false
      })

      let glucoseInitialState: Array<GlucoseProps> = Object.assign(
        [],
        state.glucose
      );

      glucoseInitialState.map((item) => {
        item.value = false
      })

      return { ...INITIAL_STATE, protein: proteinInitialState, blood: bloodInitialState, glucose: glucoseInitialState };
    }


    default:
      return state;
  }
};
