import { css } from 'styled-components';

export const forgotPasswordStyles = () => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .main-container {
      display: flex;
      flex-direction: column;
      width: 80%;
      justify-content: center;
      align-items: center;
      .title {
        text-align: center;
        margin: 8px;
      }
    }
  `;
};
