import { css } from 'styled-components';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const campaignsStyles = () => {
  return css`
    display: flex;
    flex:1;
    align-items: center;
    justify-content:center;
    margin-top:80px;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    .div-left{
      display:flex;
      flex-direction:column;
      height:100%; 
      margin:20px; 
      width: 420px;
      .div-left-header{
        width: 420px;
        display:flex;
        justify-content:space-between;
        align-items:center;
      }
    }
    .div-right{
      display:flex;
      flex:1;
      flex-direction:column;
      height:100%;
      margin:20px; 
      .div-right-header{
        margin-top:20px;
        margin-bottom:20px;
        width: 420px;
        display:flex;
        justify-content:space-between;
        align-items:center;
      }
    }

    .card-campaigns-list{
      display: flex;
      flex-direction: column;
      /* width: 700px; */
      margin-top: 10px;
      padding: 10px;
      background-color: #fff;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.1);
      border-radius: 20px;
    }

    .card-department {
      display: flex;
      flex-direction: column;
      width: 400px;
      margin-top: 10px;
      padding: 10px;
      background-color: #fff;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.1);
      border-radius: 20px;
      .card-department-header {
        display: flex;
        flex-direction: column;
        align-items: center; 
        margin-top:20px
      
      }
      .card-department-content {  
        display: flex;
        flex-direction: column;
        align-items:center;
        margin-top:20px;
        padding:10px;
        .container{
          display:flex;
          flex-wrap:wrap;
          align-items:center;
          width:350px;
          margin:5px;
          .text-cont{
            padding: 6px 12px;
            background: #FF7E04;
            border-radius: 25px;
            color: #fff;
            font-size: 12px;
            font-weight: 500;
          }
        }
   
      }
    }

    .avatar{
      width: 120px;
      height: 120px;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.1);
      border-radius: 50%;
      margin-bottom:15px;
      object-fit:contain;
    }

  `;
};