import { combineReducers } from 'redux';
import LoginReducer from './loginReducer';
import ForgotPasswordReducer from './forgotPasswordReducer';
import ResetPasswordReducer from './resetPasswordReducer';
import CampaignsReducer from './campaignsReducer';
import CreateScreeningSheetReducer from './createScreeningSheetReducer';
import ScreeningSheetProfessionalReducer from './screeningSheetProfessionalReducer';

const reducers = combineReducers({
  login: LoginReducer,
  forgotPassword: ForgotPasswordReducer,
  resetPassword: ResetPasswordReducer,
  campaigns: CampaignsReducer,
  createScreeningSheet: CreateScreeningSheetReducer,
  screeningSheetProfessional: ScreeningSheetProfessionalReducer,
});

export type RootState = ReturnType<typeof reducers>;

export default reducers;
