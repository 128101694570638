import React, { FC } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import {
  AgeProps,
  BloodProps,
  FamilyHistoryProps,
  GenderProps,
  GlucoseProps,
  PersonalMedicalHistoryProps,
  ProteinProps,
} from '../../modules/CreateScreeningSheet/createScreeningSheet.types';

// STYLES
const styles = StyleSheet.create({
  header: {
    marginTop: '5%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingLeft: '5%',
    paddingRight: '5%',
  },

  view: {
    margin: '5px 10px',
  },

  title: {
    fontSize: '16',
    color: '#000',
    marginBottom: '5px',
  },

  subTitle: {
    fontSize: '14',
    color: '#757575',
    paddingBottom: '5px',
  },
});

type ScreeningSheetProps = {
  permission: string;
  reference: string;
  gender: GenderProps;
  size: string;
  weight: string;
  age: AgeProps;
  personalMedicalHistory: PersonalMedicalHistoryProps;
  familyHistoryOfKidneyDisease: FamilyHistoryProps;
  maximumBloodPressure: string;
  minimumBloodPressure: string;
  protein: Array<ProteinProps>;
  blood: Array<BloodProps>;
  glucose: Array<GlucoseProps>;
  medicalTreatment: string;
};

const PdfDocument: FC<ScreeningSheetProps> = (props) => {
  const {
    permission,
    reference,
    gender,
    size,
    weight,
    age,
    personalMedicalHistory,
    familyHistoryOfKidneyDisease,
    maximumBloodPressure,
    minimumBloodPressure,
    protein,
    blood,
    glucose,
    medicalTreatment,
  } = props;

  const returnProteinName = (name: string | null) => {
    switch (name) {
      case 'NEGATIVE_OR_TRACES':
        return 'Négatif ou Traces';
      case '≥0,3G/L':
        return '>= 0,3 g/l';
      case '≥1G/L':
        return '>= 1 g/l';
      case '≥3G/L':
        return '>= 3 g/l';
      default:
        return null;
    }
  };

  const returnBloodName = (name: string | null) => {
    switch (name) {
      case 'NEGATIVE_OR_TRACES':
        return 'Négatif ou Traces';
      case '1_CROIX':
        return '>= 1 croix (25 g/mm³)';
      case '2_CROIX':
        return '>= 2 croix (80 g/mm³)';
      case '3_CROIX':
        return '>= 3 croix (80 g/mm³)';
      case 'ANTICOAGULANT_TREATMENT':
        return 'Traitement anticoagulant';
      default:
        return null;
    }
  };

  const returnGlucoseName = (name: string | null) => {
    switch (name) {
      case 'NEGATIVE_OR_TRACES':
        return 'Négatif ou Traces';
      case '1_CROIX':
        return '>= 1 croix (2,5 g/l) ou (14 mmol/l)';
      case '2_CROIX':
        return '>= 2 croix (5 g/l) ou (28 mmol/l)';
      case '3_CROIX':
        return '>= 3 croix (10 g/l) ou (55 mmol/l)';
      default:
        return null;
    }
  };

  const returnAge = (age: string | null) => {
    switch (age) {
      case '≤18':
        return '<= 18 ans';
      case '18-40':
        return '18 - 40 ans';
      case '41-60':
        return '41 - 60 ans';
      case '>60':
        return '> 60 ans';
      case '>70':
        return '> 70 ans';
      case '>80':
        return '> 80 ans';
      default:
        return null;
    }
  };

  return (
    <Document>
      <Page size='A4'>
        <View style={styles.header}>
          <Text style={styles.title}>Numéro de dossier : {reference}</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.title}>informations générales</Text>
          <Text style={styles.subTitle}>
            Sexe : {gender === 'male' ? 'Homme' : 'Femme'}
          </Text>
          <Text style={styles.subTitle}>Taille : {size && `${size} Cm`}</Text>
          <Text style={styles.subTitle}>
            Poids : {weight && `${weight} Kg`}
          </Text>
          <Text style={styles.subTitle}>Age : {returnAge(age)}</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.title}>Antécédentes médicaux personnels</Text>
          <Text style={styles.subTitle}>
            Présence d’un diabéte connu :{' '}
            {personalMedicalHistory.presenceOfKnownDiabetes ? 'Oui' : 'Non'}
          </Text>
          <Text style={styles.subTitle}>
            Présence d’une maladie rénale connue :{' '}
            {personalMedicalHistory.presenceOfKnownkidneyDisease
              ? 'Oui'
              : 'Non'}
          </Text>
          <Text style={styles.subTitle}>
            Présence d’une maladie cardiovasculaire connue :{' '}
            {personalMedicalHistory.presenceOfKnownCardiovascularDisease
              ? 'Oui'
              : 'Non'}
          </Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.title}>
            Antécédentes Familiaux de maladie rénale
          </Text>
          <Text style={styles.subTitle}>
            Présence d’un diabéte connu :{' '}
            {familyHistoryOfKidneyDisease.presenceOfKnownKidneyDisease
              ? 'Oui'
              : 'Non'}
          </Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.title}>Résultat de Dépistage</Text>
          <Text style={styles.subTitle}>
            Pression artérielle maximale (systolique) : {maximumBloodPressure}
          </Text>
          <Text style={styles.subTitle}>
            {' '}
            Pression artérielle minimale (Diastilique) : {minimumBloodPressure}
          </Text>
        </View>
        {permission === 'professional' && (
          <View>
            <View style={styles.view}>
              <Text style={styles.title}>Protéines</Text>
              {protein.map((item) => {
                if (item.value) {
                  return (
                    <Text key={item.name} style={styles.subTitle}>
                      {returnProteinName(item.name)} :{' '}
                      {item.value ? 'Oui' : 'Non'}
                    </Text>
                  );
                }
              })}
            </View>

            <View style={styles.view}>
              <Text style={styles.title}>Sang</Text>
              {blood.map((item) => {
                if (item.value) {
                  return (
                    <Text key={item.name} style={styles.subTitle}>
                      {returnBloodName(item.name)} :{' '}
                      {item.value ? 'Oui' : 'Non'}
                    </Text>
                  );
                }
              })}
            </View>

            <View style={styles.view}>
              <Text style={styles.title}>Glucose</Text>
              {glucose.map((item) => {
                if (item.value) {
                  return (
                    <Text key={item.name} style={styles.subTitle}>
                      {returnGlucoseName(item.name)} :{' '}
                      {item.value ? 'Oui' : 'Non'}
                    </Text>
                  );
                }
              })}
            </View>
          </View>
        )}
        {medicalTreatment.trim().length !== 0 && (
          <View style={styles.view}>
            <Text style={styles.title}>Traitement Médicale</Text>
            <Text style={styles.subTitle}>{medicalTreatment}</Text>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default PdfDocument;
