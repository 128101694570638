import { isEmpty } from '../../utils/validations';
import { ScreeningSheetProfessionalState } from '../ScreeningSheetProfessional/screeningSheetProfessional.types';
import { CreateScreeningSheetState } from './createScreeningSheet.types';

export const validateFieldsCreateScreeningSheet = (
  props: CreateScreeningSheetState
) => {
  let newProps: CreateScreeningSheetState = Object.assign({}, props);

  if (isEmpty(newProps.size) && isEmpty(newProps.weight)) {
    newProps.messageError = 'Taille/Poids est obligatoire';
  } else {
    newProps.messageError = '';
  }

  let valid = false;
  if (newProps.messageError === '') {
    valid = true;
  }

  return { newProps, valid };
};

export const createScreeningSheetObject = (
  screeningSheet: CreateScreeningSheetState,
  campaignId: string
) => {
  let newScreeningSheet: any = Object.assign(
    {},
    screeningSheet
  );

  newScreeningSheet = {
    gender: screeningSheet.gender,
    size: screeningSheet.size,
    weight: screeningSheet.weight,
    age: screeningSheet.age,
    personalMedicalHistory: screeningSheet.personalMedicalHistory,
    familyHistoryOfKidneyDisease:
      screeningSheet.familyHistoryOfKidneyDisease,
    maximumBloodPressure: screeningSheet.maximumBloodPressure,
    minimumBloodPressure: screeningSheet.minimumBloodPressure,
    protein: screeningSheet.protein,
    blood: screeningSheet.blood,
    glucose: screeningSheet.glucose,
    medicalTreatment: screeningSheet.medicalTreatment,
    campagne: campaignId,
  };

  return newScreeningSheet;
};

export const updateScreeningSheetObject = (
  screeningSheet: ScreeningSheetProfessionalState,
  campaignId: string
) => {


  let newScreeningSheet: any = Object.assign(
    {},
    screeningSheet
  );

  // newScreeningSheet = {
  //   gender: screeningSheet.gender,
  //   size: screeningSheet.size,
  //   weight: screeningSheet.weight,
  //   age: screeningSheet.age,
  //   personalMedicalHistory: screeningSheet.personalMedicalHistory,
  //   familyHistoryOfKidneyDisease:
  //   screeningSheet.familyHistoryOfKidneyDisease,
  //   maximumBloodPressure: screeningSheet.maximumBloodPressure,
  //   minimumBloodPressure: screeningSheet.minimumBloodPressure,
  //   protein:screeningSheet.protein,
  //   blood:screeningSheet.blood,
  //   glucose:screeningSheet.glucose,
  //   medicalTreatment:screeningSheet.medicalTreatment,
  //   campagne: campaignId,
  // };

  return newScreeningSheet;
};


export const calculIMC = (weight: string, size: string) => {
  if (weight && size) {
    let weightNumber = parseInt(weight, 10);
    let sizeNumber = parseInt(size, 10) / 100;
    return (weightNumber / Math.pow(sizeNumber, 2)).toFixed(1);
  }
};

