import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';

//Styles
//Material
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
//Commons
import Button from '../../../commons/Button';

//Actions
// import { URL_IMAGE } from '../../../actions/configApi';
//Utils
import { getItemFromLocalStorage } from '../../../utils/storage';
import { Text } from '../../../commons';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      boxShadow: 'none',
    },
    container: {
      maxHeight: '100%',
    },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  })
);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F6F9FD',
      },
    },
  })
)(TableRow);

interface StickyHeadTableProps extends RouteComponentProps {
  data?: Array<any>;
  count: number;
  page: number;
  rowsPerPage: number;
  changePage: (event: unknown, newPage: number) => void;
  changeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  //   clickDelete: (value: string) => void;
}

const StickyHeadTable: React.FC<StickyHeadTableProps> = (props) => {
  const classes = useStyles();

  const {
    data,
    count,
    rowsPerPage,
    page,
    changePage,
    changeRowsPerPage,
    // clickDelete,
  } = props;
  const user: any = getItemFromLocalStorage('user');

  const onClickSeeSheets = (campaignId: string) => {
    if (user.permission === 'professional') {
      props.history.push(
        `/campaigns/screening-sheet-professional/${campaignId}`
      );
    } else {
      props.history.push(`/campaigns/new-screening-sheet/${campaignId}`);
    }
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell>Campagne</TableCell>
              <TableCell>Lieu</TableCell>
              <TableCell>Date de début</TableCell>
              <TableCell>Date de fin</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          {data &&
            data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                if (moment(row.endDate).isAfter(moment())) {
                  return (
                    <TableBody>
                      <StyledTableRow
                        hover
                        role='checkbox'
                        tabIndex={-1}
                        key={row._id}
                      >
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.address}</TableCell>
                        <TableCell>
                          {row.startDate &&
                            moment(row.startDate).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell>
                          {row.endDate &&
                            moment(row.endDate).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => onClickSeeSheets(row._id)}
                            width={100}
                            background='#FF67C6'
                          >
                            Créer fiche
                          </Button>
                        </TableCell>
                      </StyledTableRow>
                    </TableBody>
                  );
                }
                // else {
                //   return (
                //     <Box>
                //       <Text>Les Fiches ont expiré</Text>
                //     </Box>
                //   );
                // }
              })}
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage='Lignes par page'
        rowsPerPageOptions={[5, 10, 25, 100]}
        component='div'
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={changePage}
        onChangeRowsPerPage={changeRowsPerPage}
      />
    </Paper>
  );
};

export default withRouter(StickyHeadTable);
