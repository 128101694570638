import { css } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const emptyDataStyles = () => {
  return css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .img-container {
      width: 180px;
      height: 180px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        filter: hue-rotate(312deg);
        opacity: 0.5;
      }
    }
    .circular-progress {
      color: #dd0089;
    }
  `;
};
