import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
// Styles
import { useStyles } from './campaigns.styles';
// Components
import NavBar from '../../components/NavBar';
// Modules
import Campaigns from '../../modules/Campaigns';
import ScreeningSheetProfessional from '../../modules/ScreeningSheetProfessional';
import CreateScreeningSheet from '../../modules/CreateScreeningSheet';
import NotFound from '../../modules/NotFound';

const CampaignsRouter: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.rootAppRouter}>
      <NavBar title='Campagnes'>
        <Switch>
          <Route path='/campaigns' component={Campaigns} exact />
          <Route
            path='/campaigns/screening-sheet-professional/:campaignId'
            component={ScreeningSheetProfessional}
          />
          <Route
            path='/campaigns/new-screening-sheet/:campaignId'
            component={CreateScreeningSheet}
          />
          {/* <Route path='/compagnes/:compagneId/add-fiche' component={Fiche}/>  */}
          {/* <Route path='/compagnes/*' component={NotFound} /> */}
        </Switch>
      </NavBar>
    </div>
  );
};

export default CampaignsRouter;
