import { isEmpty } from '../../utils/validations';
import { ScreeningSheetProfessionalState } from '../ScreeningSheetProfessional/screeningSheetProfessional.types';


export const validateFieldsUpdateScreeningSheet = (
    props: ScreeningSheetProfessionalState
) => {
    let newProps: ScreeningSheetProfessionalState = Object.assign({}, props);

    if (isEmpty(newProps.sheetSlected.size) && isEmpty(newProps.sheetSlected.weight)) {
        newProps.messageError = 'Taille/Poids est obligatoire';
    } else {
        newProps.messageError = '';
    }

    let valid = newProps.messageError === '';


    return { newProps, valid };
};



export const updateScreeningSheetObject = (
    screeningSheet: ScreeningSheetProfessionalState,
    campaignId: string
) => {
    const { sheetSlected } = screeningSheet
    const newScreeningSheet = {
        gender: sheetSlected.gender,
        size: sheetSlected.size,
        weight: sheetSlected.weight,
        age: sheetSlected.age,
        personalMedicalHistory: sheetSlected.personalMedicalHistory,
        familyHistoryOfKidneyDisease:
            sheetSlected.familyHistoryOfKidneyDisease,
        maximumBloodPressure: sheetSlected.maximumBloodPressure,
        minimumBloodPressure: sheetSlected.minimumBloodPressure,
        protein: sheetSlected.protein,
        blood: sheetSlected.blood,
        glucose: sheetSlected.glucose,
        medicalTreatment: sheetSlected.medicalTreatment,
        campagne: campaignId,
        status: 'finish'
    };

    return newScreeningSheet;
};

export const calculIMC = (weight: string, size: string) => {
    if (weight && size) {
      let weightNumber = parseInt(weight, 10);
      let sizeNumber = parseInt(size, 10) / 100;
      return (weightNumber / Math.pow(sizeNumber, 2)).toFixed(1);
    }
  };


