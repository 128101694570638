import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Avatar, CircularProgress } from '@material-ui/core';
//Icons
import { Icon } from 'react-icons-kit';
import { ic_content_copy } from 'react-icons-kit/md/ic_content_copy';
//Styles
import styled from 'styled-components';
import { campaignsStyles } from './campaigns.styles';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
//Types
import { CampaignsProps } from './campaigns.types';
//Commons
import { Text, Input, Button } from '../../commons';
//Components
import EmptyData from '../../components/EmptyData';
import Table from '../../components/Tables/CampaignsTable';
//Modules

//Assets/Images
import logo from '../../assets/images/logo.png';
import emptyState from '../../assets/images/emptyState.svg';
//Actions
import {
  campaignsGetDepartments,
  campaignsGetCampaignsByDepartment,
  campaignsPropsChanged,
} from '../../actions/campaignsActions';
//Reducers
import { RootState } from '../../reducers';
import { URL_IMAGE } from '../../actions/configApi';
//Utils
import { getItemFromLocalStorage } from '../../utils/storage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
      position: 'static',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const Campaigns: React.FC<CampaignsProps> = (props) => {
  const classes = useStyles();
  const [copy, setCopy] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [limit, setlimitPage] = React.useState(10);
  const url = 'https://francerein.app/department';
  const intervener: any = getItemFromLocalStorage('user');
  const { departments, department, campaigns, departmentDetail } = props;

  React.useEffect(() => {
    props.campaignsGetDepartments(intervener._id);
    /* eslint-disable-next-line */
  }, []);

  React.useEffect(() => {
    if (departments.length > 0) {
      props.campaignsGetCampaignsByDepartment(departments[0]._id);
    }
    /* eslint-disable-next-line */
  }, [departments]);

  const handleSelectChange = (event: React.ChangeEvent<{ value: any }>) => {
    props.campaignsPropsChanged('department', event.target.value);
    props.campaignsGetCampaignsByDepartment(event.target.value);
  };

  const handleClickSave = () => {};

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setlimitPage(+event.target.value);
    setPage(0);
  };
  console.log('campaigns', campaigns);

  return (
    <Wrapper>
      {campaigns.length !== 0 && (
        <div className='div-left'>
          <div className='div-left-header'>
            <span>Département</span>
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel htmlFor='outlined-age-native-simple'>
                Departement
              </InputLabel>
              <Select
                native
                value={department}
                onChange={handleSelectChange}
                label='department'
                id='department'
              >
                {departments.map((option: any) => (
                  <option value={option._id}>{option.name}</option>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='card-department'>
            <div className='card-department-header'>
              <img
                alt='Avatar'
                src={
                  departmentDetail && departmentDetail.logo
                    ? `${URL_IMAGE}/${departmentDetail.logo}`
                    : logo
                }
                className='avatar'
              />
              <Button
                onClick={() => {}}
                width={180}
                color='#fff'
                border='1px solid #FF67C6'
                background='#FF67C6'
              >
                {departmentDetail ? departmentDetail.name : ''}
              </Button>
            </div>
            <div className='card-department-content'>
              <div className='container'>
                <Text
                  fontSize={16}
                  fontWeight={500}
                  lineHeight={24}
                  color='#373737'
                  style={{ marginRight: ' 10px' }}
                >
                  Région :
                </Text>
                <Text
                  fontSize={16}
                  fontWeight={500}
                  lineHeight={24}
                  color='#373737'
                >
                  {departmentDetail && departmentDetail.region
                    ? departmentDetail.region.name
                    : ''}
                </Text>
              </div>
              <div className='container'>
                <Text
                  fontSize={16}
                  fontWeight={500}
                  lineHeight={24}
                  color='#373737'
                  style={{ marginRight: ' 10px' }}
                >
                  Responsables :
                </Text>
                {departmentDetail &&
                  departmentDetail.responsibles &&
                  departmentDetail.responsibles.map((item: any) => {
                    return (
                      <div className='text-cont'>
                        {`${item.firstName} ${item.lastName}`}
                      </div>
                    );
                  })}
              </div>

              <div className='container'>
                <Text
                  fontSize={16}
                  fontWeight={500}
                  lineHeight={24}
                  color='#373737'
                  style={{ marginRight: ' 10px' }}
                >
                  Président :
                </Text>
                {departmentDetail && departmentDetail.president && (
                  <div className='text-cont'>
                    {`${departmentDetail.president.firstName} ${departmentDetail.president.lastName}`}
                  </div>
                )}
              </div>

              <div className='container'>
                <Text
                  fontSize={16}
                  fontWeight={500}
                  lineHeight={24}
                  color='#373737'
                  style={{ marginRight: ' 10px' }}
                >
                  Vice Président :
                </Text>
                {departmentDetail && departmentDetail.vicePresident && (
                  <div className='text-cont'>
                    {`${departmentDetail.vicePresident.firstName} ${departmentDetail.vicePresident.lastName}`}
                  </div>
                )}
              </div>

              {/* <div className='container'>
                 <Text
                  fontSize={16}
                  fontWeight={500}
                  lineHeight={24}
                  color='#373737'
                  style={{ marginRight: ' 10px' }}
                >
                  Lien :
                </Text> 
                 <div>
                  <Text
                    fontSize={15}
                    fontWeight={400}
                    lineHeight={24}
                    color='#DD0089'
                  >
                    {url}
                  </Text>
                  <CopyToClipboard
                    text={url}
                    onCopy={() => {
                      setCopy(true);
                    }}
                  >
                    <Icon
                      icon={ic_content_copy}
                      size='20'
                      style={{
                        color: '#DD0089',
                        marginLeft: '10px',
                        cursor: 'pointer',
                      }}
                    />
                  </CopyToClipboard>
                </div> 
              </div>*/}
            </div>
          </div>
        </div>
      )}

      <div className='div-right'>
        <div className='div-right-header'>
          <span>Liste des campagnes</span>
        </div>
        <div className='card-campaigns-list'>
          <EmptyData
            data={campaigns}
            loading={false}
            message='La liste des campaigns est vide'
            messageError={''}
            image={emptyState}
          >
            <Table
              data={campaigns}
              count={campaigns.length}
              rowsPerPage={limit}
              page={page}
              changePage={handleChangePage}
              changeRowsPerPage={handleChangeRowsPerPage}
              // clickFiches={() => {
              //   props.history.push('/fiche');
              // }}
            ></Table>
          </EmptyData>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => campaignsStyles()}
`;

const mapStateToProps = ({ campaigns }: RootState) => {
  return {
    departments: campaigns.departments,
    department: campaigns.department,
    campaigns: campaigns.campaigns,
    departmentDetail: campaigns.departmentDetail,
  };
};

export const connector = connect(mapStateToProps, {
  campaignsGetDepartments,
  campaignsGetCampaignsByDepartment,
  campaignsPropsChanged,
});
export default connector(Campaigns);
