import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularProgress } from '@material-ui/core';
//Icons

//Styles
import styled from 'styled-components';
import { contactUsStyles } from './contactUs.styles';
//Types
// import { AutomatedTextsFormProps } from './automatedTexts.types';
//Commons
import { Text, Input, Button } from '../../commons';
import NavBar from '../../components/NavBar';
import { Fragment } from 'react';
//Components

//Modules

//Assets/Images

//Actions

//Reducers
// import { RootState } from '../../reducers';
//Utils
// import { getItemFromLocalStorage } from '../../utils/storage';

const ContactUs: React.FC<any> = (props) => {
  React.useEffect(() => {
    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {};

  const handleClickSave = () => {};

  return (
    <React.Fragment>
      <NavBar title='Campagnes'></NavBar>
      <Wrapper>
        <span>ContactUs</span>
      </Wrapper>
    </React.Fragment>
  );
};

const Wrapper = styled('div')`
  ${() => contactUsStyles()}
`;

export default ContactUs;
