import axios from 'axios';
import { URL } from './configApi';
import {
  SCREENING_SHEET_PROFESSIONAL_GET_SHEETS_SUCCESS,
  SCREENING_SHEET_PROFESSIONAL_GET_SHEETS_FAILED,
  SCREENING_SHEET_PROFESSIONAL_GET_SCREENING_SHEET_SUCCESS,
  SCREENING_SHEET_PROFESSIONAL_GET_SCREENING_SHEET_FAILED,
  SCREENING_SHEET_PROFESSIONAL_PROPS_CHANGED,
  GET_SCREENING_SHEET_PROFESSIONAL,
  UPDATE_SCREENING_SHEET_PROFESSIONAL_SUCCESS,
  UPDATE_SCREENING_SHEET_PROFESSIONAL_FAILED,
  SCREENING_SHEET_PROFESSIONAL_INITIAL_STATE,
  SCREENING_SHEET_PROFESSIONAL_PROPS_VALIDATION,
  SCREENING_SHEET_PROFESSIONAL_FILTER_SHEETS,
  AppThunk,
} from './types';
import {
  ScreeningSheetProfessionalState,
  ScreeningSheetProfessionalInitialStateAction,
  ScreeningSheetProfessionalPropsChangedAction,
  ScreeningSheetProfessionalGetScreeningSheetAction,
} from '../modules/ScreeningSheetProfessional/screeningSheetProfessional.types';

//Utils
import {
  validateFieldsUpdateScreeningSheet,
  updateScreeningSheetObject,
} from '../modules/ScreeningSheetProfessional/screeningSheetProfessional.utils';

export const screeningSheetProfessionalPropsChanged = (
  prop: string,
  value: any
): ScreeningSheetProfessionalPropsChangedAction => {
  return { type: SCREENING_SHEET_PROFESSIONAL_PROPS_CHANGED, prop, value };
};

export const clickScreeningSheet = (
  index: number
): ScreeningSheetProfessionalGetScreeningSheetAction => {
  return { type: GET_SCREENING_SHEET_PROFESSIONAL, payload: index };
};

export const screeningSheetProfessionalInitialState = (): ScreeningSheetProfessionalInitialStateAction => {
  return { type: SCREENING_SHEET_PROFESSIONAL_INITIAL_STATE };
};

export const screeningSheetProfessionalGetSheets = (
  campaignId: string
): AppThunk => (dispatch) => {
  axios
    .get(`${URL}/screening_sheet/campagne/${campaignId}`)
    .then(({ data }: { data: any }) => {
      if (data.status === 'success') {
        dispatch({
          type: SCREENING_SHEET_PROFESSIONAL_GET_SHEETS_SUCCESS,
          payload: data.response.data,
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: SCREENING_SHEET_PROFESSIONAL_GET_SHEETS_FAILED,
          payload: 'Network Error',
        });
      }
    });
};

export const screeningSheetProfessionalGetSheetById = (
  screningSheetId: string
): AppThunk => (dispatch) => {
  axios
    .get(`${URL}/screening_sheet/${screningSheetId}`)
    .then(({ data }: { data: any }) => {
      if (data.status === 'success') {
        dispatch({
          type: SCREENING_SHEET_PROFESSIONAL_GET_SCREENING_SHEET_SUCCESS,
          payload: data.response.data,
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: SCREENING_SHEET_PROFESSIONAL_GET_SCREENING_SHEET_FAILED,
          payload: 'Network Error',
        });
      }
    });
};

export const updateScreeningSheetProfessional = (
  props: ScreeningSheetProfessionalState,
  campaignId: string,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newProps, valid } = validateFieldsUpdateScreeningSheet(props);

  dispatch({
    type: SCREENING_SHEET_PROFESSIONAL_PROPS_VALIDATION,
    payload: newProps,
  });
  const ScreeningSheet = updateScreeningSheetObject(newProps, campaignId);

  if (valid && ScreeningSheet) {
    dispatch({
      type: SCREENING_SHEET_PROFESSIONAL_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });
    axios
      .put(`${URL}/screening_sheet/${props.sheetSlected._id}`, ScreeningSheet)
      .then(({ data }: { data: any }) => {
        if (data.status === 'success') {
          dispatch({
            type: UPDATE_SCREENING_SHEET_PROFESSIONAL_SUCCESS,
            payload: data.response.data,
          });
          callback();
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_SCREENING_SHEET_PROFESSIONAL_FAILED,
          payload: 'error',
        });
      });
  }
};

export const screeningSheetProfessionalFilterSheets = (
  search: string,
  sheets: Array<any>
): AppThunk => (dispatch) => {
  let sheetsFilter: Array<any> = Object.assign([], sheets);
  console.log('search === ', search);
  console.log('sheets === ', sheets);

  dispatch({
    type: SCREENING_SHEET_PROFESSIONAL_PROPS_CHANGED,
    prop: 'loading',
    value: true,
  });

  const result = sheetsFilter.filter((item: any) => {
    return item.reference.toLocaleLowerCase().match(search.toLocaleLowerCase());
  });

  dispatch({
    type: SCREENING_SHEET_PROFESSIONAL_FILTER_SHEETS,
    search,
    payload: result,
  });
};
